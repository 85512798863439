<template>
  <div
    class="slide-in-container"
    :class="{'active' : show === true}"
  >
    <div class="slide-in-bg" />
    <div class="slide-in-content">
      <div class="flex justify-between">
        <div>
          <h1 class="text-xl mb-2">
            Edit email signature
          </h1>
          <p class="leading-normal text-grey-semi-light mb-5 pr-10">
            The email signature will be added below the content of your email when you are sending out within the Octoa.
          </p>
        </div>
        <div>
          <div
            class="close"
            @click="cancel"
          />
        </div>
      </div>
      <div>
        <!-- <v-editor
          :allow-token="false"
          :update-pending.sync="signature.updatePending"
          :editor-content.sync="signature.content"
        /> -->
        <v-ckeditor v-model="signature.content" />
        <div
          class="actions relative"
        >
          <div
            v-if="!loading"
            class="flex mt-4"
          >
            <div class="px-4">
              <v-button
                class="green-btn"
                @click="save"
              >
                Save
              </v-button>
            </div>
            <div>
              <v-button
                class="btn--secondary"
                @click="cancel"
              >
                Cancel
              </v-button>
            </div>
          </div>
          <div
            v-else
            class="text-center"
          >
            <img
              src="@/assets/img/icons/loader.svg"
              width="40"
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import auth from '@/auth'
import { logException } from '@/helpers'

export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    signature:{
      type: Object,
      default: null,
    },
  },
  data(){
    return {
      loading: false,
      user: auth.user(),
    }
  },
  mounted(){
  },
  methods: {
    cancel(){
      this.$emit('close')
    },
    async save(){

      this.loading = true
      const postData = {
        userId: this.user.me.id,
        content: this.signature.content,
      }

      try {
        const { data } = await this.$api.get('settings').updateEmail(postData)
        this.$emit('saved', data.emailSignature)
        setTimeout(() => this.loading = false, 300)
      } catch(e){
        this.$toasted.global.api_error(e)
        logException(e)
      }
    }
  }
}
</script>
